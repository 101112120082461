<div class="pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Appointments</h1>
</div>
<!-- Show user data when logged in -->

<div>
    <button mat-raised-button color="primary" class="push-left mb-3" (click)="resetCustomer()">
        Clear Demo Data
    </button>
</div>

<div *ngIf="isTech" class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <!-- Name -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.FName }} {{ element.LName }}</td>
        </ng-container>

        <!-- Program -->
        <ng-container matColumnDef="Program">
            <th mat-header-cell *matHeaderCellDef>Program</th>
            <td mat-cell *matCellDef="let element">{{ element.Model }}</td>
        </ng-container>

        <!-- Lesson -->
        <ng-container matColumnDef="Lesson">
            <th mat-header-cell *matHeaderCellDef>Lesson</th>
            <td mat-cell *matCellDef="let element">{{ element.Lesson }}</td>
        </ng-container>

        <!-- Lesson -->
        <ng-container matColumnDef="Phone">
            <th mat-header-cell *matHeaderCellDef>Phone</th>
            <td mat-cell *matCellDef="let element">{{ element.Issure }}</td>
        </ng-container>

        <!-- Select -->
        <ng-container matColumnDef="Select">
            <th mat-header-cell *matHeaderCellDef>Select</th>
            <td mat-cell *matCellDef="let element">
                <img
                    [src]="element.LoggedIn === 'Not Signed in' ? '/assets/icons/offline.png' : '/assets/icons/on.png'" />
            </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="FName">
            <th mat-header-cell *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let element">{{ element.FName }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="LName">
            <th mat-header-cell *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let element">{{ element.LName }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="ApptTime">
            <th mat-header-cell *matHeaderCellDef>Appointment</th>
            <td mat-cell *matCellDef="let element">{{ element.ApptTime }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="UserID">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">{{ element.UserID }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Completed">
            <th mat-header-cell *matHeaderCellDef>Completed</th>
            <td mat-cell *matCellDef="let element">{{ element.Completed === "0" ? "" : "Completed" }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.LoggedIn === "Not Signed in" ? "" : "Ready" }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element; let i = index">
                <button mat-raised-button color="primary" class="push-right" (click)="emailUserLink(i, element)">
                    Send Link
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="callCustomer(row)"></tr>
    </table>

    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
</div>

<div *ngIf="!isTech" class="mat-elevation-z8">
    Your Next appointment is
    <!-- {{ message | async | json }} -->
</div>