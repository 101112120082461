import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit, ViewChild, NgZone } from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { ApiService } from "../../shared/services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

export interface Subject {
    name: string;
}

@Component({
    selector: "app-edit-employee",
    templateUrl: "./edit-employee.component.html",
    styleUrls: ["./edit-employee.component.css"],
})
export class EditEmployeeComponent implements OnInit {
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    @ViewChild("chipList", { static: true }) chipList;
    @ViewChild("resetEmployeeForm", { static: true }) myNgForm;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    employeeForm: FormGroup;
    subjectArray: Subject[] = [];
    SectioinArray: any = ["A", "B", "C", "D", "E"];

    ngOnInit() {
        this.updateBookForm();
    }

    constructor(
        public fb: FormBuilder,
        private router: Router,
        private ngZone: NgZone,
        private actRoute: ActivatedRoute,
        private employeeApi: ApiService,
    ) {
        var id = this.actRoute.snapshot.paramMap.get("id");
        this.employeeApi.GetEmployee(id).subscribe((data) => {
            if (data.length == 1) {
                var employee = data[0];
                console.log(employee);
                this.employeeForm = this.fb.group({
                    ID: id,
                    FName: [employee.FName, [Validators.required]],
                    LName: [employee.LName, [Validators.required]],
                    Description: [employee.Description, [Validators.required]],
                });
            }
        });
    }

    /* Reactive book form */
    updateBookForm() {
        this.employeeForm = this.fb.group({
            FName: ["", [Validators.required]],
            LName: ["", [Validators.required]],
            Description: ["", [Validators.required]],
        });
    }

    /* Add dynamic languages */
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        // Add language
        if ((value || "").trim() && this.subjectArray.length < 5) {
            this.subjectArray.push({ name: value.trim() });
        }
        // Reset the input value
        if (input) {
            input.value = "";
        }
    }

    /* Remove dynamic languages */
    remove(subject: Subject): void {
        const index = this.subjectArray.indexOf(subject);
        if (index >= 0) {
            this.subjectArray.splice(index, 1);
        }
    }

    /* Date */
    formatDate(e) {
        var convertDate = new Date(e.target.value).toISOString().substring(0, 10);
        this.employeeForm.get("dob").setValue(convertDate, {
            onlyself: true,
        });
    }

    /* Get errors */
    public handleError = (controlName: string, errorName: string) => {
        return this.employeeForm.controls[controlName].hasError(errorName);
    };

    /* Update book */
    updateEmployeeForm() {
        console.log(this.employeeForm.value);
        var id = this.actRoute.snapshot.paramMap.get("id");
        if (window.confirm("Are you sure you want to update?")) {
            this.employeeApi.UpdateEmployee(id, this.employeeForm.value).subscribe((res) => {
                this.ngZone.run(() => this.router.navigateByUrl("/employees-list"));
            });
        }
    }
}
