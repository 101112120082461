import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { auth } from "firebase/app";
import { Observable } from "rxjs";
import { User } from "../../model/user";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    user: User;

    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        private api: ApiService,
        public ngZone: NgZone, // NgZone service to remove outside scope warning
    ) {
        /* Saving user data in localstorage when
    logged in and setting up null when logged out */
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.user = user;
                localStorage.setItem("user", JSON.stringify(this.user));
            } else {
                localStorage.setItem("user", null);
            }
        });
    }

    // Sign in with email/password
    SignIn(email, password) {
        return this.afAuth.auth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                this.ngZone.run(() => {
                    this.router.navigate(["dashboard"]);
                });
                localStorage.setItem("user", JSON.stringify(result.user));
                this.SetUserData(result.user);
            })
            .catch((error) => {
                window.alert(error.message);
            });
    }

    // Create email/password
    CreateUser(email, password, callback) {
        return this.afAuth.auth
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                window.alert(error.message);
            });
    }

    // Sign up with email/password
    SignUp(email, password) {
        return this.afAuth.auth
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {
                /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
                this.SendVerificationMail();
                this.SetUserData(result.user);
            })
            .catch((error) => {
                window.alert(error.message);
            });
    }

    // Send email verfificaiton when new user sign up
    SendVerificationMail() {
        return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
            this.router.navigate(["verify-email-address"]);
        });
    }

    // Reset Forggot password
    ForgotPassword(passwordResetEmail) {
        return this.afAuth.auth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                window.alert("Password reset email sent, check your inbox.");
            })
            .catch((error) => {
                window.alert(error);
            });
    }

    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const localUser = localStorage.getItem("user");
        if (localUser == null) return false;

        return JSON.parse(localUser) !== null ? true : false;
        // && user.emailVerified !== false
    }

    get getUserData(): User {
        const user = JSON.parse(localStorage.getItem("user"));
        return user !== null ? user : null;
    }

    // Sign in with Google
    GoogleAuth() {
        return this.AuthLogin(new auth.GoogleAuthProvider());
    }

    // Auth logic to run auth providers
    AuthLogin(provider) {
        return this.afAuth.auth
            .signInWithPopup(provider)
            .then((result) => {
                this.ngZone.run(() => {
                    this.router.navigate(["dashboard"]);
                });
                this.SetUserData(result.user);
            })
            .catch((error) => {
                window.alert(error);
            });
    }

    /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    SetUserData(user: User) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        this.api.updateFCM(user.email).subscribe(
            (result) => {
                console.log(result);
            },
            (error: any) => {
                console.error(error);
            },
        );

        this.user = user;
        return userRef.set(
            {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                photoURL: user.photoURL,
                emailVerified: user.emailVerified,
            },
            {
                merge: true,
            },
        );
    }

    // Sign out
    SignOut() {
        return this.afAuth.auth.signOut().then(() => {
            localStorage.removeItem("user");
            this.router.navigate(["sign-in"]);
        });
    }
}
