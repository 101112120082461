import { Component } from "@angular/core";
import { Global } from "./shared/common/global";
import { MessagingService } from "./shared/services/messaging.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
})
export class AppComponent {
    title = "push-notification";
    message;
    constructor(private messagingService: MessagingService) {}
    ngOnInit() {
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.message = this.messagingService.currentMessage;
    }
    ngAfterViewInit() {
        //this.messagingService.playaudiomuted();
    }
}
