<div>
    <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Incoming</h1>
    </div>

    <div style="font-weight: 500">Trainer Name</div>
    <div>{{ callSession["fName"] }} {{ callSession["lName"] }}</div>

    <div style="display: flex;">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <img src="../../../assets/icons/long-call.png" class="long-image-button" />
            <div>
                <button mat-fab (click)="answerClicked()"
                    style="margin-right: 25px; background-color: rgb(11, 204, 11);">
                    <mat-icon class="material-icons">videocam</mat-icon>
                </button>

                <button mat-fab color="primary" (click)="endClicked()">
                    <mat-icon class="material-icons">call_end</mat-icon>
                </button>
            </div>
        </div>
        <div style="margin-right: auto;"></div>
    </div>
</div>