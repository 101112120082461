<div class="pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">User Profile</h1>
</div>
<!-- Show user data when logged in -->
<div class="row" *ngIf="authService.user as user">
    <div class="col-md-12">
        <div class="media">
            <img class="align-self-start mr-5 img-thumbnail rounded-circle"
                src="{{ user.photoURL ? user.photoURL : '/assets/dummy-user.png' }}" alt="{{ user.displayName }}" />
            <div class="media-body">
                <h1>
                    Hello: <strong>{{ user.displayName ? user.displayName : "User" }}</strong>
                </h1>
                <p>
                    User ID: <strong>{{ user.uid }}</strong>
                </p>
                <p>
                    Email: <strong>{{ user.email }}</strong>
                </p>
                <p>
                    Email Verified: <strong>{{ user.emailVerified }}</strong>
                </p>
            </div>
        </div>
    </div>
</div>