<div class="sidebar-sticky">
    <ul class="nav flex-column">
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{ active: isHome }" href="dashboard"> <i class="fas fa-home"></i>Home </a>
        </li>
        <li class="nav-item" *ngIf="false">
            <a class="nav-link" [ngClass]="{ active: isEmployees }" href="add-employee">
                <i class="fas fa-user"></i>Add Employee
            </a>
        </li>
        <li class="nav-item" *ngIf="false">
            <a class="nav-link" [ngClass]="{ active: isEmployees }" href="employees-list">
                <i class="fas fa-user"></i>Employees List
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{ active: isAppointments }" href="appointments">
                <i class="fas fa-user"></i>Appointments
            </a>
        </li>
        <!-- Calling SignOut() Api from AuthService -->
        <li class="nav-item">
            <a class="nav-link" (click)="authService.SignOut()"> <i class="fas fa-sign-out-alt"></i>Log out </a>
        </li>
    </ul>
</div>
