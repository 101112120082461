<!-- Top navigation -->
<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <!-- <a class="navbar-brand col-sm-3 col-md-2 mr-0"> -->
    <img class="brand-logo p-0" src="assets/total_dog.svg" width="85" height="62" alt="IDMRTV Logo" />
    <!-- </a> -->
</nav>

<!-- Sidebar navigation -->
<div class="container-fluid">
    <div class="row" [ngClass]="showDogPaw ? 'dog-paw' : ''">
        <nav class="col-md-2 d-md-block bg-light sidebar">
            <app-sidemenu></app-sidemenu>
        </nav>

        <!-- Main content -->
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
            <div class="inner-adjust">
                <router-outlet></router-outlet>
            </div>
        </main>
    </div>
</div>