import { Component, OnInit } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { Global } from "src/app/shared/common/global";

@Component({
    selector: "app-sidemenu",
    templateUrl: "./sidemenu.component.html",
    styleUrls: ["./sidemenu.component.css"],
})
export class SidemenuComponent implements OnInit {
    public isEmployees: boolean;
    public isHome: boolean;
    public isAppointments: boolean;
    constructor(public authService: AuthService) {}

    ngOnInit() {
        this.isHome = Global.currentPage == "dashboard";
        this.isEmployees = Global.currentPage == "employees";
        this.isAppointments = Global.currentPage == "appointments";
        console.log(this.isHome);
    }
}
