<div class="pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Video Call</h1>
</div>

<!-- Streams Count:{{streams.length}} -->

<!-- <img src="../../../assets/icons/call-btn.png" class="image-button" (click)="sessionClicked()"> -->

<div class="content" *ngIf="session">
    <div class="row">
        <div class="col-sm-2">
            <div *ngIf="isHost">
                <div style="font-weight: 600">Name</div>
                <div>{{ appointment.FName }} {{ appointment.LName }}</div>

                <div class="pt-2" style="font-weight: 600">Class</div>
                <div>{{ appointment.Model }} {{ appointment.Lesson }}</div>

                <div class="pt-2" style="font-weight: 600">Customer Phone Number</div>
                <div>{{ appointment.Issure }}</div>

                <div style="margin-top: 25px; font-size: 1.2rem; font-weight: 500">
                    Content Library
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Video Lists</mat-label>
                        <mat-select (selectionChange)="startCoviewUrl($event.value)">
                            <mat-option *ngFor="let video of contentLibrary; index as i" [value]="video">Video {{i}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>

                <!-- <video *ngFor="let video of contentLibrary" [src]="video" preload="metadata" crossorigin="anonymous"
                    class="stored-video" (click)="startCoview($event.target)"></video> -->
            </div>
            <div *ngIf="!isHost">
                <div>Trainer</div>
                <div>coming soon...</div>

                <div class="pt-2">Class</div>
                <div>coming soon...</div>

                <div class="pt-2">Trainer Phone Number</div>
                <div>coming soon...</div>
            </div>
        </div>
        <!-- The callee view / the front and center -->
        <div class="col-sm-8">
            <div *ngIf="mainStream && !coviewing" class="large-view-container">
                <app-subscriber [stream]="mainStream" [session]="session" class="largeView"></app-subscriber>
            </div>

            <div *ngIf="coviewing" class="large-view-container">
                <div class="largeView">
                    <video #coviewVideo [src]="coviewVideoUrl" controls preload="metadata" crossorigin="anonymous"
                        style="height: 100%; width: 100%; position: absolute; top: 0; left: 0;"></video>
                </div>

                <app-coview-publisher style="display: none;" [session]="session" [videoElement]="coviewVideo">
                </app-coview-publisher>
            </div>

            <div style="display: flex; flex-direction: row; justify-content: center;">
                <button mat-fab class="fab-button" color="primary"
                    [style.background-color]="!isMute ? 'var(--grey)' : ''" (click)="muteClicked()">
                    <mat-icon *ngIf="isMute" class="material-icons">mic_off</mat-icon>
                    <mat-icon *ngIf="!isMute" class="material-icons">mic</mat-icon>
                </button>

                <button mat-fab class="fab-button" style="background-color: var(--grey);" (click)="switchClicked()">
                    <mat-icon class="material-icons">flip_camera_ios</mat-icon>
                </button>

                <button mat-fab class="fab-button" color="primary"
                    [style.background-color]="!screenSharing ? 'var(--grey)' : ''" (click)="screenClicked()">
                    <mat-icon *ngIf="!screenSharing" class="material-icons">screen_share</mat-icon>
                    <mat-icon *ngIf="screenSharing" class="material-icons">stop_screen_share</mat-icon>
                </button>

                <button *ngIf="coviewing" mat-fab class="fab-button" color="primary" (click)="stopCoview()">
                    <mat-icon class="material-icons">stop</mat-icon>
                </button>

                <button mat-fab class="fab-extended-button" color="primary" (click)="endClicked()">
                    <mat-icon class="material-icons">call_end</mat-icon>
                </button>
            </div>
        </div>
        <!-- The caller's view / right side of the call UI -->
        <div class="col-sm-2">
            <app-publisher [session]="session" (newItemEvent)="addItem($event)" class="small-view"></app-publisher>
            <app-screen [session]="session" #SharedScreen class="small-view"
                [style.display]="screenSharing ? 'block' : 'none'"></app-screen>

            <div *ngFor="let stream of streams">
                <app-subscriber *ngIf="stream != mainStream" [stream]="stream" [session]="session"
                    class="small-view"></app-subscriber>
            </div>
        </div>
    </div>
</div>

<div>
    <!-- <button (click)="shareClicked()">Share your screen </button> -->

    <!-- <button (click)="screenClicked()">Share your screen </button> -->
</div>