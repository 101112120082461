<div class="displayTable" style="background-color: white;">
    <div class="displayTableCell">
        <img src="../../../assets/total_dog.svg" class="logo">

        <div class="authBlock">
            <h3>Reset Password</h3>

            <p class="text-center">Please enter your email address to request a password reset.</p>

            <div class="formGroup">
                <input type="email" class="formControl" placeholder="Email Address" #passwordResetEmail required />
            </div>

            <!-- Calling ForgotPassword from AuthService Api -->
            <div class="formGroup">
                <input type="submit" class="btn btnPrimary" value="Reset Password"
                    (click)="authService.ForgotPassword(passwordResetEmail.value)" />
            </div>
        </div>

        <div class="redirectToLogin">
            <span>Go back to ? <span class="redirect" routerLink="/sign-in">Log In</span></span>
        </div>
    </div>
</div>