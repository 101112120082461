import { Component, ElementRef, AfterViewInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { OpentokService } from "../../shared/services/opentok.service";

const publish = () => {};

@Component({
    selector: "app-publisher",
    templateUrl: "./publisher.component.html",
    styleUrls: ["./publisher.component.css"],
})
export class PublisherComponent implements AfterViewInit {
    @ViewChild("publisherDiv") publisherDiv: ElementRef;
    @Input() session: OT.Session;
    @Output() newItemEvent = new EventEmitter<OT.Publisher>();
    publisher: OT.Publisher;
    publishing: Boolean;

    constructor(private opentokService: OpentokService) {
        this.publishing = false;
    }

    ngAfterViewInit() {
        const OT = this.opentokService.getOT();
        this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {
            insertMode: "append",
            width: "100%",
            height: "100%",
        });
        this.newItemEvent.emit(this.publisher);

        if (this.session) {
            if (this.session["isConnected"]()) {
                this.publish();
            }
            this.session.on("sessionConnected", () => this.publish());
        }
    }

    publish() {
        this.session.publish(this.publisher, (err) => {
            if (err) {
                alert(err.message);
            } else {
                this.publishing = true;
            }
        });
    }
}
