// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyBHpeC2z05FKirEFWJWj5C8fWujtpL8KiE",
        authDomain: "talkshoprtvtest.firebaseapp.com",
        databaseURL: "https://talkshoprtvtest.firebaseio.com",
        projectId: "talkshoprtvtest",
        storageBucket: "talkshoprtvtest.appspot.com",
        messagingSenderId: "303009993084",
        appId: "1:303009993084:web:2af23361bddaa495",
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
