import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { OpentokService } from "../../shared/services/opentok.service";

@Component({
    selector: "app-coview-publisher",
    templateUrl: "./coview-publisher.component.html",
    styleUrls: ["./coview-publisher.component.css"],
})
export class CoviewPublisherComponent implements AfterViewInit {
    @ViewChild("publisherDiv") publisherDiv: ElementRef;
    @Input() session: OT.Session;
    @Input() videoElement: any;
    @Output() newItemEvent = new EventEmitter<OT.Publisher>();

    publisher: OT.Publisher;
    publishing: Boolean;
    videoTrack: MediaStreamTrack | null;
    audioTrack: MediaStreamTrack | null;

    constructor(private opentokService: OpentokService) {
        this.publishing = false;
    }

    ngAfterViewInit() {
        let stream: MediaStream = this.videoElement.captureStream();
        stream.onaddtrack = (event) => {
            let track = event.track;

            if (track.kind == "video" && !this.videoTrack) {
                this.videoTrack = track.clone();
            } else if (track.kind == "audio" && !this.audioTrack) {
                this.audioTrack = track.clone();
            }

            if (this.videoTrack && this.audioTrack) {
                this.init();
                stream.onaddtrack = null;
            }
        };
    }

    ngOnDestroy() {
        this.session.unpublish(this.publisher);
        this.publisher.destroy();
    }

    init() {
        const OT = this.opentokService.getOT();

        this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {
            fitMode: "contain",
            width: 480,
            height: 270,
            videoSource: this.videoTrack,
            audioSource: this.audioTrack,
        });
        this.newItemEvent.emit(this.publisher);

        if (this.session) {
            if (this.session["isConnected"]) {
                this.publish();
            }
            this.session.on("sessionConnected", () => this.publish());
        }
    }

    publish() {
        this.session.publish(this.publisher, (err) => {
            if (err) {
                console.error(err.message);
            } else {
                this.publishing = true;
            }
        });
    }
}
