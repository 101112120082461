import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Global } from "src/app/shared/common/global";
import { ApiService } from "src/app/shared/services/api.service";
import { AuthService } from "../../shared/services/auth.service";
import { SidemenuComponent } from "../../sidemenu/sidemenu.component";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
    constructor(
        public api: ApiService,
        public authService: AuthService,
        public router: Router,
        public ngZone: NgZone,
    ) {}

    ngOnInit() {
        Global.currentPage = "dashboard";
        console.log(Global.currentPage);
        // this.api.getVideos("cage.liu.70+1@gmail.com");
    }
}
