import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Appointment } from "src/app/model/appointment";
import { Data } from "src/app/model/data";
import { ApiService } from "src/app/shared/services/api.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { OpentokService } from "src/app/shared/services/opentok.service";
import config from "src/config";
import { ScreenComponent } from "../screen/screen.component";
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
    selector: "app-call-screen",
    templateUrl: "./call-screen.component.html",
    styleUrls: ["./call-screen.component.css"],
    providers: [OpentokService],
})
export class CallScreenComponent implements OnInit {
    @ViewChild("SharedScreen") screenComponent: ScreenComponent;

    onClick() {
        alert("hello");
    }

    public myPublisher: OT.Publisher;

    public appointment: Appointment;
    public callID: String = "";
    public callSession: Map<string, string>;
    public redialCount = 0;
    public timer;
    public connectedTime = 0;
    public status: String = "";
    public isMute: boolean = false;
    public screenSharing: boolean = false;
    public coviewing: boolean = false;
    title = "Angular Basic Video Chat";

    startedArchive: boolean = false;
    contentLibrary: string[] = [];

    session: OT.Session;
    streams: Array<OT.Stream> = [];
    changeDetectorRef: ChangeDetectorRef;
    coviewVideoUrl: string | null = null;

    mainStream: OT.Stream | null;

    private get lastStream(): OT.Stream {
        return this.streams[this.streams.length - 1];
    }

    get isHost(): boolean {
        return this.appointment != null || this.appointment != undefined;
    }

    constructor(
        private api: ApiService,
        public authService: AuthService,
        public router: Router,
        private ref: ChangeDetectorRef,
        private opentokService: OpentokService,
        private data: Data,
    ) {
        if (data.appointment != null) {
            this.appointment = data.appointment;
        }
        this.changeDetectorRef = ref;
    }

    ngOnInit() {
        this.getContentLibrary();
        this.callID = config.CallID;
        this.startTimer();

        this.opentokService
            .initSession()
            .then((session: OT.Session) => {
                this.session = session;
                this.session.on("streamCreated", (event) => {
                    // This should be run only when the client connects.
                    if (this.streams.length == 0 && !this.startedArchive) this.startArchive();

                    this.streams.push(event.stream);
                    this.mainStream = this.lastStream;
                    this.changeDetectorRef.detectChanges();
                });
                this.session.on("streamDestroyed", (event) => {
                    const idx = this.streams.indexOf(event.stream);
                    if (idx > -1) {
                        this.streams.splice(idx, 1);
                        this.mainStream = this.lastStream;

                        this.changeDetectorRef.detectChanges();
                        if (this.streams.length === 0) {
                            this.session.disconnect();
                            this.finish();
                        }
                    }
                });
            })
            .then(() => this.opentokService.connect())
            .catch((err) => {
                console.error(err);
                // alert("Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.");
            });
    }

    startTimer() {
        this.timer = setInterval(() => {
            this.checkStatus();
        }, 1000);
    }

    clearTimer() {
        clearInterval(this.timer);
    }

    finish() {
        config.CallID = "";
        this.router.navigate(["appointments"]);
    }

    addItem(newItem: OT.Publisher) {
        this.myPublisher = newItem;
    }

    checkStatus() {
        // if (self.status != "Closed") {
        //   self.status = "";
        //   this.clearTimer();
        // }
        if (this.callID !== "") {
            this.api.callStatus(this.callID).subscribe(
                (result: string) => {
                    console.log("Call Status:" + result);
                    switch (result) {
                        case "Pending":
                            self.status = "Pending";
                            break;
                        case "Answered":
                            self.status = "Answered";
                            break;
                        case "Declined":
                            this.clearTimer();
                            this.finish();
                            break;
                        case "Ignored":
                        case "Not responding":
                            console.log("checkStatus:" + result);
                            this.clearTimer();
                            this.finish();
                            break;
                        case "Cancelled":
                            this.clearTimer();
                            this.finish();
                            break;
                        default:
                            break;
                    }
                },
                (error: any) => {
                    console.log(error);
                },
            );
        }
    }

    switchClicked(): void {
        this.myPublisher.cycleVideo();
    }

    muteClicked(): void {
        this.isMute = !this.isMute;
        this.myPublisher.publishAudio(!this.isMute);
    }

    endClicked(): void {
        this.opentokService.disconnect();
        this.clearTimer();
        this.api.callCancelled(this.callID).subscribe(
            (result) => {
                this.finish();
            },
            (error: any) => {
                console.log(error);
            },
        );
    }

    screenClicked(): void {
        this.screenSharing = !this.screenSharing;

        if (this.screenSharing) {
            this.screenComponent.startSharing();
        } else {
            this.screenComponent.stopScreenShare();
        }
    }

    startCoviewUrl(video: string): void {
        if (!this.appointment) return;
        this.coviewing = true;
        this.coviewVideoUrl = video;
        this.mainStream = null;
    }

    startCoview(video: HTMLVideoElement): void {
        if (!this.appointment) return;
        this.coviewing = true;
        this.coviewVideoUrl = video.src;
        this.mainStream = null;
    }

    stopCoview(): void {
        if (!this.appointment) return;
        this.coviewing = false;
        this.coviewVideoUrl = null;
        this.mainStream = this.lastStream;
    }

    startArchive(): void {
        if (!this.isHost) return;

        this.startedArchive = true;
        this.api.startArchive(this.appointment.Email, this.session.sessionId);
    }

    async getContentLibrary() {
        if (!this.isHost) return;

        let videos = await this.api.getVideos(this.appointment.Email);
        this.contentLibrary = videos;
    }
}
