import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Appointment } from "src/app/model/appointment";
import { Data } from "src/app/model/data";
import { User } from "src/app/model/user";
import { ApiService } from "src/app/shared/services/api.service";
import { AuthService } from "src/app/shared/services/auth.service";
import config from "src/config";

@Component({
    selector: "app-call-customer",
    templateUrl: "./call-customer.component.html",
    styleUrls: ["./call-customer.component.css"],
})
export class CallCustomerComponent implements OnInit {
    public appointment: Appointment;
    public user: User;
    public screenName: String;
    public callID: String = "";
    public callSession: Map<string, string>;
    public redialCount = 0;
    public timer;

    constructor(
        private api: ApiService,
        public authService: AuthService,
        public router: Router,
        public ngZone: NgZone,
        private data: Data,
    ) {
        if (data.appointment != null) {
            this.appointment = data.appointment;
        }
        this.user = authService.user;
    }

    ngOnInit(): void {
        this.showScreen("PreCall");
        this.callID = "";
    }

    close(): void {
        this.router.navigate(["appointments"]);
    }

    sessionClicked(): void {
        var url = "https://youtu.be/DmlQ-M0Vi84";
        window.open(url, "_blank");
    }

    sendCall() {
        this.api.SendCall(this.appointment.Email, this.user.email).subscribe(
            async (response: Response) => {
                this.callSession = (await response.json())[0];
                this.callID = this.callSession["callID"];
                config.CallID = this.callSession["callID"];
                config.API_KEY = this.callSession["apiKey"];
                config.SESSION_ID = this.callSession["session"];
                config.TOKEN = this.callSession["token"];
                this.showScreen("Ringing");
                this.startTimer();
            },
            (error: any) => {
                console.log(error);
            },
        );
    }

    animateRing(ringing: boolean) {
        if (ringing) {
            // ringManager.beginRing()
            // imgRing.image = UIImage.animatedImageNamed("long-call-", duration: 2.0)
            // imgRing.startAnimating()
        } else {
            // ringManager.stopRing()
            // imgRing.stopAnimating()
        }
    }

    showScreen(screenName: string) {
        this.screenName = screenName;
        switch (screenName) {
            case "PreCall":
                this.animateRing(false);
                this.callID = "";
                this.redialCount = 0;
                break;
            case "Ringing":
                //            headerView.title = "CONNECTING"
                this.animateRing(true);
                break;
            case "RedialCall":
                //headerView.title = "REDIAL CALL"
                this.animateRing(false);
                this.redialCount = 1;
                break;
            case "SendVideo":
                //headerView.title = "VIDEO RECORDED"
                this.animateRing(false);
                break;
            case "MissedCall":
                //headerView.title = "RECORD VIDEO"
                this.animateRing(false);
                break;
            case "Final":
                //headerView.title = "VIDEO SENT"
                this.animateRing(false);
                break;
            case "CallEnd":
                //headerView.title = "CALL COMPLETED"
                this.animateRing(false);
                break;
            default:
                this.animateRing(false);
                break;
        }
    }

    startTimer() {
        this.timer = setInterval(() => {
            this.checkStatus();
        }, 1000);
    }

    clearTimer() {
        clearInterval(this.timer);
    }

    checkStatus() {
        if (this.callID !== "") {
            this.api.callStatus(this.callID).subscribe(
                (result: string) => {
                    console.log("Call Status:" + result);
                    switch (result) {
                        case "Pending":
                            break;
                        case "Answered":
                            this.clearTimer();
                            // self.animateRing(ringing: false)
                            // self.performSegue(withIdentifier: "segMakeCall", sender: self.callSession)
                            this.data.appointment = this.appointment;
                            this.router.navigate(["call-screen"]);
                            break;
                        case "Declined":
                            this.clearTimer();
                            this.showScreen("MissedCall");
                            break;
                        case "Ignored":
                        case "Not responding":
                            this.clearTimer();
                            if (this.redialCount === 0) {
                                this.showScreen("RedialCall");
                            } else {
                                this.showScreen("MissedCall");
                            }
                            break;
                        case "Cancelled":
                            this.clearTimer();
                            this.showScreen("PreCall");
                            break;
                        default:
                            break;
                    }
                },
                (error: any) => {
                    console.log(error);
                },
            );
        }
    }
}
