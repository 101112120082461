<div class="pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Edit Employee</h1>
</div>
<!-- Form -->
<form [formGroup]="employeeForm" (ngSubmit)="updateEmployeeForm()" #resetEmployeeForm="ngForm" novalidate>
    <input type="hidden" ngModel name="ID" value="" />
    <!-- Left block -->
    <mat-card>
        <div class="controlers-wrapper">
            <!-- Name -->
            <mat-form-field class="example-full-width">
                <input matInput placeholder="First Name" formControlName="FName" />
                <mat-error *ngIf="handleError('FName', 'required')">
                    You must provide a<strong>first name</strong>
                </mat-error>
            </mat-form-field>

            <!-- Email -->
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Last Name" formControlName="LName" />
                <mat-error *ngIf="handleError('LName', 'required')">
                    You must provide a<strong>last name</strong>
                </mat-error>
            </mat-form-field>

            <!-- Description -->
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Description" formControlName="Description" />
                <mat-error *ngIf="handleError('Description', 'required')">
                    You must provide a<strong>description</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </mat-card>

    <!-- Submit & Reset -->
    <mat-card>
        <div class="full-wrapper button-wrapper">
            <div class="button-wrapper">
                <button mat-flat-button color="warn">Update</button>
            </div>
        </div>
    </mat-card>
</form>
