import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";
import { Router } from "@angular/router";
import { Data } from "src/app/model/data";
import { NgZone } from "@angular/core";

@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    audio: HTMLAudioElement;
    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private api: ApiService,
        private zone: NgZone,
        private data: Data,
        public router: Router,
    ) {
        this.angularFireMessaging.messaging.subscribe((_messaging) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        });
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.api.setToken(token);
                console.log(token);
            },
            (err) => {
                console.error("Unable to get permission to notify.", err);
            },
        );
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((payload) => {
            console.log("new message received. ", payload);
            const callinfo = payload["data"];
            if (callinfo) {
                this.zone.run(() => {
                    this.data.incomingcallSession = callinfo;
                    this.router.navigate(["incoming-call"]);
                });
            } else {
                this.currentMessage.next(payload);
                //this.playaudio();
            }
        });
    }
    public playaudio() {
        console.log("playaudio");
        //this.audio = new Audio();
        //this.audio.src = "../../assets/home-ringtone-4438.mp3";
        //this.audio.load();
        this.audio.play();
    }

    public playaudiomuted() {
        console.log("playaudio muted");
        this.audio = new Audio();
        this.audio.src = "../../assets/home-ringtone-4438.mp3";
        this.audio.load();
        this.audio.volume = 0;
        this.audio.play();
        this.audio.volume = 1;
    }

    public stopaudio() {
        console.log("stopaudio");
        this.audio.pause();
    }
}
