import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import * as OT from "@opentok/client";

@Component({
    selector: "app-subscriber",
    templateUrl: "./subscriber.component.html",
    styleUrls: ["./subscriber.component.css"],
})
export class SubscriberComponent implements AfterViewInit {
    @ViewChild("subscriberDiv") subscriberDiv: ElementRef;
    @Input() session: OT.Session;
    @Input()
    set stream(value: OT.Stream) {
        if (this._stream) {
            this.session.unsubscribe(this.subscriber);
        }

        this._stream = value;
        this.subscribe();
    }

    _stream: OT.Stream | null = null;
    subscriber: OT.Subscriber | null = null;

    constructor() {}

    ngAfterViewInit() {
        this.subscribe();
    }

    subscribe() {
        if (!this._stream || !this.session) return;

        this.subscriber = this.session.subscribe(
            this._stream,
            this.subscriberDiv.nativeElement,
            {
                insertMode: "append",
                width: "100%",
                height: "100%",
            },
            (err) => {
                if (err) {
                    alert(err.message);
                }
            },
        );
    }
}
