import { Router } from "@angular/router";
import { Component, OnInit, ViewChild, NgZone } from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { ApiService, RTVUser } from "../../shared/services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/app/shared/services/auth.service";

export interface Subject {
    name: string;
}

@Component({
    selector: "app-add-employee",
    templateUrl: "./add-employee.component.html",
    styleUrls: ["./add-employee.component.css"],
})
export class AddEmployeeComponent implements OnInit {
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    @ViewChild("chipList", { static: true }) chipList;
    @ViewChild("resetEmployeeForm", { static: true }) myNgForm;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    employeeForm: FormGroup;
    subjectArray: Subject[] = [];
    SectioinArray: any = ["A", "B", "C", "D", "E"];

    ngOnInit() {
        this.submitBookForm();
    }

    constructor(
        public fb: FormBuilder,
        private router: Router,
        private ngZone: NgZone,
        private authService: AuthService,
        private employeeApi: ApiService,
    ) {}

    /* Reactive book form */
    submitBookForm() {
        this.employeeForm = this.fb.group({
            FName: ["", [Validators.required]],
            LName: ["", [Validators.required]],
            Email: ["", [Validators.required]], //Email: ['cage.liu.70+1@gmail.com', [Validators.required]],
            Description: ["", [Validators.required]],
        });
    }

    /* Add dynamic languages */
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        // Add language
        if ((value || "").trim() && this.subjectArray.length < 5) {
            this.subjectArray.push({ name: value.trim() });
        }
        // Reset the input value
        if (input) {
            input.value = "";
        }
    }

    /* Remove dynamic languages */
    remove(subject: Subject): void {
        const index = this.subjectArray.indexOf(subject);
        if (index >= 0) {
            this.subjectArray.splice(index, 1);
        }
    }

    /* Date */
    formatDate(e) {
        var convertDate = new Date(e.target.value).toISOString().substring(0, 10);
        this.employeeForm.get("dob").setValue(convertDate, {
            onlyself: true,
        });
    }

    /* Get errors */
    public handleError = (controlName: string, errorName: string) => {
        return this.employeeForm.controls[controlName].hasError(errorName);
    };

    /* Submit book */
    submitEmployeeForm() {
        if (this.employeeForm.valid) {
            //this.authService.CreateUser(this.employeeForm.value.Email, "temptemp", (result) => {
            this.employeeApi.AddEmployee(this.employeeForm.value).subscribe((resp) => {
                console.log(resp);
                this.ngZone.run(() => this.router.navigateByUrl("/employees-list"));
            });
            //  console.log(result);
            //});

            // this.employeeApi.AddEmployee(this.employeeForm.value).subscribe(res => {
            //   this.ngZone.run(() => this.router.navigateByUrl('/employees-list'))
            // });
        }
    }
}
