import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
    selector: "app-site-layout",
    templateUrl: "./site-layout.component.html",
    styleUrls: ["./site-layout.component.css"],
})
export class SiteLayoutComponent implements OnInit {
    showDogPaw: boolean = true;

    constructor(router: Router) {
        // https://stackoverflow.com/a/58975995
        router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                const url = urlAfterRedirects.split(";")[0];
                this.showDogPaw = !(url === "/call-screen");
            });
    }

    ngOnInit() {
        console.log(window.innerWidth);
        // if (window.innerWidth < 768) {
        //   this.sidenav.fixedTopGap = 55;
        //   this.opened = false;
        // } else {
        //   this.sidenav.fixedTopGap = 55;
        //   this.opened = true;
        // }
    }
}
