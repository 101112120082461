import { Component, OnInit, NgZone, ViewChild } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { SidemenuComponent } from "../../sidemenu/sidemenu.component";
import { Global } from "src/app/shared/common/global";
import { ApiService, RTVUser } from "../../shared/services/api.service";
import { Employee } from "src/app/model/employee";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

@Component({
    selector: "app-employees",
    templateUrl: "./employees.component.html",
    styleUrls: ["./employees.component.css"],
})
export class EmployeesComponent implements OnInit {
    EmployeeData: any = [];
    dataSource: MatTableDataSource<Employee>;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    displayedColumns: string[] = ["ID", "FName", "LName", "UserID", "Description", "action"];

    constructor(
        private employeeApi: ApiService,
        public authService: AuthService,
        public ngZone: NgZone,
        public router: Router,
    ) {
        this.employeeApi.GetEmployees().subscribe((data) => {
            this.EmployeeData = data;
            this.dataSource = new MatTableDataSource<Employee>(this.EmployeeData);
            setTimeout(() => {
                this.dataSource.paginator = this.paginator;
            }, 0);
        });
    }

    ngOnInit() {}

    deleteEmployee(index: number, e) {
        if (window.confirm("Are you sure")) {
            const data = this.dataSource.data;
            data.splice(this.paginator.pageIndex * this.paginator.pageSize + index, 1);
            this.dataSource.data = data;
            this.employeeApi.DeleteEmployee(e.ID).subscribe();
        }
    }

    // createRTVUser(fname:string, lname:string, email:string, token:string) {
    //   var rtvuser = <RTVUser>{};
    //   rtvuser.firstName = fname;
    //   rtvuser.lastName = lname;
    //   rtvuser.user_id = email;
    //   rtvuser.fcm_token = token;
    //   this.api.fcmInsert(rtvuser).subscribe(resp => {
    //     console.log(resp);
    //    });
    // }
}
