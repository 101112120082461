import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Appointment } from "src/app/model/appointment";
import { PeriodicElement } from "../../components/appointments/appointments.component";
import { Employee } from "../../model/employee";

const localUrl = "assets/data/smartphone.json";
//const appServer = 'https://idmrtv.com/demoStuff';
//const appServer = 'http://localhost:4200/backend';
const appServer = "https://www.idmsrtv.com/backend";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    // endpoint: string = 'http://localhost:4000/api';
    endpoint: string = "api";
    headers = new HttpHeaders().set("Content-Type", "application/json");
    token: string = "WebSimulator";

    constructor(private http: HttpClient) {}

    getBaseUrl() {
        return "http://18.224.251.208";
    }

    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }

    private log(message: string) {
        console.log(message);
    }

    setToken(token: string) {
        this.token = token;
    }

    getSmartphone(): Observable<HttpResponse<PeriodicElement[]>> {
        return this.http.get<PeriodicElement[]>(localUrl, { observe: "response" });
    }

    getUsers(): Observable<any> {
        return this.http
            .get<Appointment[]>(appServer + "/get_users.php", { observe: "response" })
            .pipe(catchError(this.handleError<Appointment[]>("getUsers", [])));
    }

    getAppointments(): Observable<any> {
        return this.http
            .get<Appointment[]>(appServer + "/get_appointments.php", { observe: "response" })
            .pipe(catchError(this.handleError<Appointment[]>("get_appointments", [])));
    }

    fcmInsert(rtvUser: RTVUser): Observable<any> {
        let data =
            "firstName=" +
            rtvUser.firstName +
            "&lastName=" +
            rtvUser.lastName +
            "&fcm_token=" +
            rtvUser.fcm_token +
            "&user_id=" +
            rtvUser.user_id;
        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        // var para = new HttpParams();
        // para.append("firstName", rtvUser.firstName);
        // para.append("lastName", rtvUser.lastName);
        // para.append("fcm_token", rtvUser.fcm_token);
        // para.append("user_id", rtvUser.user_id);
        // const body=JSON.stringify(rtvUser);
        return this.http
            .post(appServer + "/fcm_insert.php", data, { headers: header, observe: "response" })
            .pipe(catchError(this.errorMgmt));
    }

    // updateFCM
    updateFCM(email: string): Observable<any> {
        let API_URL = appServer + "/fcm_update.php";

        let body = new URLSearchParams();
        body.append("userID", email);
        body.append("fcm_token", this.token);

        let promise = fetch(API_URL, {
            method: "POST",
            body: body,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        return from(promise);
    }

    // getIsTech
    getIsTech(user: String): Observable<any> {
        let API_URL = appServer + "/get_is_tech.php";

        let postdata = "user=" + user.replace("+", "%2B");

        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(API_URL, postdata, { headers: header }).pipe(catchError(this.errorMgmt));
    }

    // Send Call
    SendCall(toUser: String, fromUser: String): Observable<any> {
        let apiUrl = appServer + "/send_notification.php";

        let body = new URLSearchParams();
        body.append("toUserID", toUser.toString());
        body.append("fromUserID", fromUser.toString());
        body.append("title", "Incoming Call");
        body.append("message", "Incoming call from trainer.");

        let promise = fetch(apiUrl, {
            method: "POST",
            body: body,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        return from(promise);
    }

    // Call Status
    callStatus(callID: String): Observable<any> {
        let apiUrl = appServer + "/call_status.php";

        let body = new URLSearchParams();
        body.append("callID", callID.toString());

        let promise = fetch(apiUrl, {
            method: "POST",
            body: body,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }).then((e) => e.text());

        return from(promise);
    }

    // Call Answer
    callAnswer(callID: String): Observable<any> {
        let API_URL = appServer + "/call_answer.php";

        let postdata = "callID=" + callID;

        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(API_URL, postdata, { headers: header }).pipe(catchError(this.errorMgmt));
    }

    // Call Declined
    callDeclined(callID: String): Observable<any> {
        let API_URL = appServer + "/call_declined.php";

        let postdata = "callID=" + callID;

        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(API_URL, postdata, { headers: header }).pipe(catchError(this.errorMgmt));
    }

    // Call Cancelled
    callCancelled(callID: String): Observable<any> {
        let API_URL = appServer + "/call_cancelled.php";

        let postdata = "callID=" + callID;

        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(API_URL, postdata, { headers: header }).pipe(catchError(this.errorMgmt));
    }

    // Add employee
    AddEmployee(data): Observable<any> {
        let API_URL = appServer + "/insert_tech.php";
        let postdata =
            "FName=" +
            data.FName +
            "&LName=" +
            data.LName +
            "&Description=" +
            data.Description +
            "&Email=" +
            data.Email.replace("+", "%2B");
        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(API_URL, postdata, { headers: header }).pipe(catchError(this.errorMgmt));
    }

    // Get all employees
    GetEmployees() {
        return this.http.get(appServer + "/get_techs.php");
    }

    // Get employee
    GetEmployee(id): Observable<any> {
        return (
            this.http
                .get(appServer + "/get_techs.php?ID=" + id)
                // let API_URL = `${this.endpoint}/read-employee/${id}`;
                // return this.http.get(API_URL, { headers: this.headers })
                .pipe(
                    map((res: Response) => {
                        return res || {};
                    }),
                    catchError(this.errorMgmt),
                )
        );
    }

    // Update employee
    UpdateEmployee(id, data): Observable<any> {
        let API_URL = appServer + "/update_tech.php";
        let postdata =
            "FName=" + data.FName + "&LName=" + data.LName + "&Description=" + data.Description + "&ID=" + id;
        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(API_URL, postdata, { headers: header }).pipe(catchError(this.errorMgmt));
    }

    // Email User Login Link
    EmailUserLink(email): Observable<any> {
        let API_URL = appServer + "/email-user-link.php";
        let postdata = "Email=" + email.replace("+", "%2B");
        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(API_URL, postdata, { headers: header }).pipe(catchError(this.errorMgmt));
    }

    // Delete employee
    DeleteEmployee(id): Observable<any> {
        var API_URL = `${this.endpoint}/delete-employee/${id}`;
        return this.http.delete(API_URL).pipe(catchError(this.errorMgmt));
    }

    // Call reset customer
    ResetCustomer(): Observable<any> {
        let API_URL = appServer + "/call_resetcustomer.php";

        var header = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(API_URL, "", { headers: header }).pipe(catchError(this.errorMgmt));
    }

    // Error handling
    errorMgmt(error: HttpErrorResponse) {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

    startArchive(clientId: string, sessionId: string): Promise<Response> {
        let apiUrl = `${appServer}/archive/start.php`;

        let body = new URLSearchParams();
        body.append("clientId", clientId.toString());
        body.append("sessionId", sessionId.toString());

        return fetch(apiUrl, {
            method: "POST",
            body: body,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    async getVideos(clientId: string): Promise<string[]> {
        let apiUrl = `${appServer}/get_videos.php`;

        let body = new URLSearchParams();
        body.append("email", clientId.toString());

        let response = await fetch(apiUrl, {
            method: "POST",
            body: body,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });

        return response.json();
    }
}

export interface RTVUser {
    firstName: string;
    lastName: string;
    user_id: string;
    fcm_token: string;
}
