<!-- <img src="../../../assets/icons/call-btn.png" class="image-button" (click)="sessionClicked()"> -->


<div class="pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2" *ngIf="screenName === 'PreCall'">Call Customer</h1>
    <h1 class="h2" *ngIf="screenName === 'Ringing'">Ringing</h1>
    <h1 class="h2" *ngIf="screenName === 'MissedCall'">Missed Call</h1>
    <h1 class="h2" *ngIf="screenName === 'RedialCall'">Redial Call</h1>
</div>

<div style="font-weight: 500">Name</div>
<div>{{ appointment.FName }} {{ appointment.LName }}</div>

<div *ngIf="screenName === 'PreCall'">
    <div style="font-weight: 500">Class</div>
    <div>{{ appointment.Model }} {{ appointment.Lesson }}</div>
</div>

<div *ngIf="screenName === 'PreCall'">
    <div style="font-weight: 500">Customer Phone Number</div>
    <div>{{ appointment.Issure }}</div>
</div>

<div style="margin-top: 25px;">
    <button mat-fab *ngIf="screenName === 'PreCall' || screenName === 'Ringing'" (click)="sendCall()" class="button">
        <mat-icon class="material-icons">call</mat-icon>
    </button>

    <button mat-fab *ngIf="screenName === 'MissedCall'" (click)="sendCall()" class="button">
        <mat-icon class="material-icons">voice_chat</mat-icon>
    </button>

    <button mat-fab *ngIf="screenName === 'RedialCall'" (click)="sendCall()" class="button">
        <mat-icon class="material-icons">replay</mat-icon>
    </button>

    <button mat-fab style="background-color: var(--grey);" (click)="close()">
        <mat-icon class="material-icons" style="color: white;">close</mat-icon>
    </button>
</div>