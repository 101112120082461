import { BrowserModule } from "@angular/platform-browser";
//import { MatTableModule } from '@angular/material/table';
//import { MatPaginatorModule } from '@angular/material/paginator';

// App routing modules
import { AppRoutingModule } from "./shared/routing/app-routing.module";

// App components
import { AppComponent } from "./app.component";
import { AddEmployeeComponent } from "./components/add-employee/add-employee.component";
import { AppointmentsComponent } from "./components/appointments/appointments.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { EditEmployeeComponent } from "./components/edit-employee/edit-employee.component";
import { EmployeesListComponent } from "./components/employees-list/employees-list.component";
import { EmployeesComponent } from "./components/employees/employees.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { VerifyEmailComponent } from "./components/verify-email/verify-email.component";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";

// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "../environments/environment";

/* Angular material */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularMaterialModule } from "./material.module";

/* Reactive form services in Angular 8 */
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Auth service
import { AsyncPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { CallCustomerComponent } from "./components/call-customer/call-customer.component";
import { CallScreenComponent } from "./components/call-screen/call-screen.component";
import { CoviewPublisherComponent } from "./components/coview-publisher/coview-publisher.component";
import { IncomingCallComponent } from "./components/incoming-call/incoming-call.component";
import { PublisherComponent } from "./components/publisher/publisher.component";
import { ScreenComponent } from "./components/screen/screen.component";
import { SubscriberComponent } from "./components/subscriber/subscriber.component";
import { ApiService } from "./shared/services/api.service";
import { AuthService } from "./shared/services/auth.service";
import { MessagingService } from "./shared/services/messaging.service";
import { OpentokService } from "./shared/services/opentok.service";
import { SiteLayoutComponent } from "./site-layout/site-layout.component";
import { TrainerScreenComponent } from "./trainer-screen/trainer-screen.component";

@NgModule({
    declarations: [
        AppComponent,
        PublisherComponent,
        CoviewPublisherComponent,
        ScreenComponent,
        SubscriberComponent,
        SignInComponent,
        SignUpComponent,
        DashboardComponent,
        SidemenuComponent,
        ForgotPasswordComponent,
        VerifyEmailComponent,
        EmployeesComponent,
        AddEmployeeComponent,
        EditEmployeeComponent,
        EmployeesListComponent,
        AppointmentsComponent,
        SiteLayoutComponent,
        CallScreenComponent,
        CallCustomerComponent,
        IncomingCallComponent,
        TrainerScreenComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularMaterialModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireMessagingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
    ],
    providers: [MessagingService, AsyncPipe, AuthService, ApiService, OpentokService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
