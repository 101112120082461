import { Component, OnInit, NgZone, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { Global } from "src/app/shared/common/global";
import { ApiService } from "../../shared/services/api.service";
import { HttpHeaders } from "@angular/common/http";
import { Data } from "src/app/model/data";
import { Appointment } from "src/app/model/appointment";
import { User } from "src/app/model/user";
import { MessagingService } from "src/app/shared/services/messaging.service";

@Component({
    selector: "app-appointments",
    templateUrl: "./appointments.component.html",
    styleUrls: ["./appointments.component.css"],
})
export class AppointmentsComponent implements OnInit {
    public user: User;
    public isTech = false;
    displayedColumns: string[] = ["ApptTime", "Name", "Program", "Lesson", "Phone", "Select"];
    dataSource = new MatTableDataSource<Appointment>([]);
    smartphone: PeriodicElement[] = [];
    headers: string[];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    message;
    constructor(
        private api: ApiService,
        public authService: AuthService,
        public router: Router,
        public ngZone: NgZone,
        private data: Data,
        private messagingService: MessagingService,
    ) {}

    ngOnInit(): void {
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.message = this.messagingService.currentMessage;
        Global.currentPage = "appointments";
        console.log(Global.currentPage);
        this.getAppointments();

        //this.getSmartphones();
        //this.dataSource.paginator = this.paginator;
    }

    ngAfterViewInit() {
        this.user = this.authService.getUserData;
        this.api.getIsTech(this.user.email).subscribe((result) => {
            if (result === 1) {
                this.isTech = true;
            } else {
                this.isTech = false;
            }
        });
        //this.messagingService.playaudiomuted();
    }

    resetCustomer() {
        this.api.ResetCustomer().subscribe((result) => {
            if (result === 1) {
                this.isTech = true;
            } else {
                this.isTech = false;
            }
        });
    }

    getAppointments() {
        this.api.getAppointments().subscribe((resp) => {
            console.log(resp);
            const keys = resp.headers.keys();
            this.headers = keys.map((key) => "${key}: ${resp.headers.get(key)}");

            // for (const data of resp.body) {
            //   console.log(data);
            //   //this.smartphone.push(data);
            // }
            this.dataSource = new MatTableDataSource<Appointment>(resp.body);
            //this.dataSource.paginator = this.paginator;
            // console.log(this.smartphone);
        });
    }

    emailUserLink(index: number, e) {
        if (window.confirm("Are you sure")) {
            // const data = this.dataSource.data;
            // data.splice((this.paginator.pageIndex * this.paginator.pageSize) + index, 1);
            // this.dataSource.data = data;
            this.api.EmailUserLink(e.UserID).subscribe();
        }
    }

    getSmartphones() {
        this.api.getSmartphone().subscribe((resp) => {
            console.log(resp);
            const keys = resp.headers.keys();
            this.headers = keys.map((key) => "${key}: ${resp.headers.get(key)}");

            for (const data of resp.body) {
                this.smartphone.push(data);
            }
            // this.dataSource = new MatTableDataSource<PeriodicElement>(this.smartphone);
            // this.dataSource.paginator = this.paginator;
            console.log(this.smartphone);
        });
    }

    callCustomer(appointment: Appointment): void {
        console.log("call-customer" + appointment.AppTime);
        this.data.appointment = appointment;
        this.router.navigate(["call-customer"]);
        //this.router.navigate(['trainer-screen']);
    }
}

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
    { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
    { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
    { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
    { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
    { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
    { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
    { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
    { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
    { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
    { position: 11, name: "Sodium", weight: 22.9897, symbol: "Na" },
    { position: 12, name: "Magnesium", weight: 24.305, symbol: "Mg" },
    { position: 13, name: "Aluminum", weight: 26.9815, symbol: "Al" },
    { position: 14, name: "Silicon", weight: 28.0855, symbol: "Si" },
    { position: 15, name: "Phosphorus", weight: 30.9738, symbol: "P" },
    { position: 16, name: "Sulfur", weight: 32.065, symbol: "S" },
    { position: 17, name: "Chlorine", weight: 35.453, symbol: "Cl" },
    { position: 18, name: "Argon", weight: 39.948, symbol: "Ar" },
    { position: 19, name: "Potassium", weight: 39.0983, symbol: "K" },
    { position: 20, name: "Calcium", weight: 40.078, symbol: "Ca" },
];
