<div class="displayTable" style="background-color: white;">
    <div class="displayTableCell">

        <img src="../../../assets/total_dog.svg" class="logo">

        <div class="authBlock shadow">
            <h3>Sign Up</h3>

            <div class="formGroup">
                <input type="email" class="formControl" placeholder="Email Address" #userEmail required />
            </div>

            <div class="formGroup">
                <input type="password" class="formControl" placeholder="Password" #userPwd required />
            </div>

            <div class="formGroup">
                <input type="button" class="btn btnPrimary" value="Sign Up"
                    (click)="authService.SignUp(userEmail.value, userPwd.value)" />
            </div>

            <div class="formGroup">
                <span class="or"><span class="orInner">Or</span></span>
            </div>

            <!-- Continue with Google -->
            <div class="formGroup">
                <button type="button" class="btn googleBtn" (click)="authService.GoogleAuth()">
                    <i class="fab fa-google-plus-g"></i>
                    Continue with Google
                </button>
            </div>
        </div>

        <div class="redirectToLogin">
            <span>Already have an account? <span class="redirect" routerLink="/sign-in">Log In</span></span>
        </div>
    </div>
</div>