<p>trainer-screen works!</p>

<div class="container">
    <div class="row">
        <div class="col-sm-2">left</div>
        <div class="col-sm-8">middle</div>
        <div class="col-sm-2">right</div>
    </div>
</div>

<img src="../../../assets/icons/call-btn.png" class="image-button" (click)="sessionClicked()" />

<div *ngIf="screenName === 'PreCall'">
    <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Call Customer</h1>
    </div>

    <div>Name</div>
    <div>{{ appointment.FName }} {{ appointment.LName }}</div>

    <div>Class</div>
    <div>{{ appointment.Model }} {{ appointment.Lesson }}</div>

    <div>Customer Phone Number</div>
    <div>{{ appointment.Issure }}</div>

    <img src="../../../assets/icons/call-btn.png" class="image-button" (click)="sendCall()" />

    <img src="../../../assets/icons/close-btn.png" class="image-button" (click)="close()" />
</div>

<div *ngIf="screenName === 'Ringing'">
    <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Ringing</h1>
    </div>

    <div>Name</div>
    <div>{{ appointment.FName }} {{ appointment.LName }}</div>

    <img src="../../../assets/icons/call-btn.png" class="image-button" (click)="sendCall()" />

    <img src="../../../assets/icons/close-btn.png" class="image-button" (click)="close()" />
</div>

<div *ngIf="screenName === 'MissedCall'">
    <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Missed Call</h1>
    </div>

    <div>Name</div>
    <div>{{ appointment.FName }} {{ appointment.LName }}</div>

    <img src="../../../assets/icons/record-btn.png" class="image-button" (click)="sendCall()" />

    <img src="../../../assets/icons/close-btn.png" class="image-button" (click)="close()" />
</div>

<div *ngIf="screenName === 'RedialCall'">
    <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Redial Call</h1>
    </div>

    <div>Name</div>
    <div>{{ appointment.FName }} {{ appointment.LName }}</div>

    <img src="../../../assets/icons/redo-redial-btn.png" class="image-button" (click)="sendCall()" />

    <img src="../../../assets/icons/close-btn.png" class="image-button" (click)="close()" />
</div>
