import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { SignInComponent } from "../../components/sign-in/sign-in.component";
import { SignUpComponent } from "../../components/sign-up/sign-up.component";
import { DashboardComponent } from "../../components/dashboard/dashboard.component";
import { ForgotPasswordComponent } from "../../components/forgot-password/forgot-password.component";
import { VerifyEmailComponent } from "../../components/verify-email/verify-email.component";

import { SidemenuComponent } from "../../sidemenu/sidemenu.component";

import { AuthGuard } from "../../shared/guard/auth.guard";
import { EmployeesComponent } from "src/app/components/employees/employees.component";
import { AppointmentsComponent } from "src/app/components/appointments/appointments.component";
import { SiteLayoutComponent } from "src/app/site-layout/site-layout.component";
import { AddEmployeeComponent } from "src/app/components/add-employee/add-employee.component";
import { EmployeesListComponent } from "src/app/components/employees-list/employees-list.component";
import { EditEmployeeComponent } from "src/app/components/edit-employee/edit-employee.component";
import { CallCustomerComponent } from "src/app/components/call-customer/call-customer.component";
import { CallScreenComponent } from "src/app/components/call-screen/call-screen.component";
import { IncomingCallComponent } from "src/app/components/incoming-call/incoming-call.component";
import { TrainerScreenComponent } from "src/app/trainer-screen/trainer-screen.component";

const routes: Routes = [
    //Site routes goes here
    {
        path: "",
        component: SiteLayoutComponent,
        children: [
            { path: "", redirectTo: "/sign-in", pathMatch: "full" },
            { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
            { path: "appointments", component: AppointmentsComponent, canActivate: [AuthGuard] },
            { path: "call-customer", component: CallCustomerComponent, canActivate: [AuthGuard] },
            { path: "trainer-screen", component: TrainerScreenComponent, canActivate: [AuthGuard] },
            { path: "incoming-call", component: IncomingCallComponent, canActivate: [AuthGuard] },
            { path: "call-screen", component: CallScreenComponent, canActivate: [AuthGuard] },
            { path: "employees", component: EmployeesComponent, canActivate: [AuthGuard] },
            { path: "employees-list", component: EmployeesListComponent, canActivate: [AuthGuard] },
            { path: "add-employee", component: AddEmployeeComponent, canActivate: [AuthGuard] },
            { path: "edit-employee/:id", component: EditEmployeeComponent, canActivate: [AuthGuard] },
        ],
    },

    { path: "", redirectTo: "/sign-in", pathMatch: "full" },
    { path: "sign-in", component: SignInComponent },
    { path: "register-user", component: SignUpComponent },
    { path: "sidemenu", component: SidemenuComponent },
    { path: "forgot-password", component: ForgotPasswordComponent },
    { path: "verify-email-address", component: VerifyEmailComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
