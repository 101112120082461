<div class="pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Employees</h1>
</div>
<!-- Show user data when logged in -->

<p *ngIf="EmployeeData.length <= 0" class="no-data">There is no employee added yet!</p>

<div class="container" *ngIf="EmployeeData.length > 0">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="ID">
                <th mat-header-cell *matHeaderCellDef>Employee ID</th>
                <td mat-cell *matCellDef="let element">{{ element.ID }}</td>
            </ng-container>

            <ng-container matColumnDef="FName">
                <th mat-header-cell *matHeaderCellDef>First Name</th>
                <td mat-cell *matCellDef="let element">{{ element.FName }}</td>
            </ng-container>

            <ng-container matColumnDef="LName">
                <th mat-header-cell *matHeaderCellDef>Last Name</th>
                <td mat-cell *matCellDef="let element">{{ element.LName }}</td>
            </ng-container>

            <ng-container matColumnDef="UserID">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.UserID }}</td>
            </ng-container>

            <ng-container matColumnDef="Description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <button
                        mat-raised-button
                        color="primary"
                        class="push-right"
                        [routerLink]="['/edit-employee/', element.ID]"
                    >
                        Edit
                    </button>
                    <button mat-raised-button color="accent" (click)="deleteEmployee(i, element)">Delete</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>
