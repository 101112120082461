export default {
    // Set this to the base URL of your sample server, such as 'https://your-app-name.herokuapp.com'.
    // Do not include the trailing slash. See the README for more information:
    SAMPLE_SERVER_BASE_URL: "http://YOUR-SERVER-URL",
    // OR, if you have not set up a web server that runs the learning-opentok-php code,
    // set these values to OpenTok API key, a valid session ID, and a token for the session.
    // For test purposes, you can obtain these from https://tokbox.com/account.
    CallID: "",
    API_KEY: "46652762",
    SESSION_ID: "1_MX40NjY1Mjc2Mn5-MTYyMzAwNDk3NzE2N35mdXBlYUJ0QUp4UmhZbXNIT1dBYTAydlV-QX4",
    TOKEN: "T1==cGFydG5lcl9pZD00NjY1Mjc2MiZzaWc9MWEyMTBkYjg5NDk0MDZjNDkzNDkzZjMxZDRjM2YzZTIwYTlmYTRjMDpzZXNzaW9uX2lkPTFfTVg0ME5qWTFNamMyTW41LU1UWXlNekF3TkRrM056RTJOMzVtZFhCbFlVSjBRVXA0VW1oWmJYTklUMWRCWVRBeWRsVi1RWDQmY3JlYXRlX3RpbWU9MTYyMzAwNDk3NyZyb2xlPXB1Ymxpc2hlciZub25jZT0xNjIzMDA0OTc3LjIxMDM0ODAyOTU2OTgmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=",
};
