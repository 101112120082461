import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";

@Component({
    selector: "app-sign-up",
    templateUrl: "./sign-up.component.html",
    styleUrls: ["./sign-up.component.css"],
})
export class SignUpComponent implements OnInit {
    constructor(
        public authService: AuthService,
        private route: ActivatedRoute,
    ) {}

    @ViewChild("userEmail", { static: true })
    userEmailInput: ElementRef;

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.userEmailInput.nativeElement.value = params.email ?? "";
        });
    }
}
